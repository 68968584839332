import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import { Row, Col, Container } from "react-bootstrap";
import ChooseUsList from "../../../components/choose-us-list";
import SectionTitleTwo from "../../../components/title-two";
import jsonDataInit from "../../../data/about/company-profile.json";
import jsonDataSection_titleInit from "../../../data/about/company-profile_section_title.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../../components/preloadPage/preload";
import {
    WhyChooseUsSection,
    ChooseUsImage,
    WhyChooseUsOffset,
    ChooseListWrpa,
} from "./style";
const WhyChooseUsArea = ({ data }) => {
    let section_titleInit = {
        showShortDec: true,
        showSubTitle: true,
        subtitle: "// WHAY CHOOSE US?",
        title:
            jsonDataSection_titleInit?.title ||
            "The Background of the Company.",
        icon: {
            src: "../../data/images/icons/pencile.png",
            alt: "Working Process",
        },
        description:
            jsonDataSection_titleInit?.text ||
            "Reliance Insurance Limited being incorporated on the 20th of March 1988, starts operation by virtue of the Certificate of Commencement of Business” issued by Registrar of Joint Stock Companies on the 22nd of March 1988. It obtains Certificate of Registration from Controller of Insurance on the 7th of April 1988. Currently the company is regulated by Insurance Development and Regulatory Authority (IDRA). Pioneering the industry Reliance Insurance Limited proudly celebrated its Silver Jubilee in 2013. The mission of Reliance is to be one of the outstanding amongst insurers in Asia by putting the customer at the heart of everything they do.",
        dividerBg: "secondary",
    };
    const [section_title, setSection_title] = useState(section_titleInit);
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a5fd064978d5747f94049d"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a8e2323d10d3dae9d1bbb9"
        )
            .then((resultData) => {
                section_titleInit.title = resultData.data.title;
                section_titleInit.text = resultData.data.text;
                setSection_title(section_titleInit);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    return (
        <WhyChooseUsSection>
            {preload && <Preload />}
            <Container className="container-max">
                <Row className="align-items-center">
                    <Col lg={12}>
                        <WhyChooseUsOffset>
                            <SectionTitleTwo
                                // subTitle={data?.section_title.subtitle}

                                title={section_title.title}
                                shortDec={section_title.description}
                                {...section_title}
                            />
                            {jsonData &&
                                jsonData?.map((item, i) => {
                                    return (
                                        <ChooseListWrpa key={i}>
                                            <ChooseUsList
                                                icon={item?.image}
                                                title={item?.title}
                                                description={item?.text}
                                            />
                                        </ChooseListWrpa>
                                    );
                                })}
                        </WhyChooseUsOffset>
                    </Col>
                    <Col lg={12}>
                        <ChooseUsImage>
                            <StaticImage
                                src="../../../data/images/about/presentChart.jpg"
                                alt=""
                                className="choose-us-img"
                            />
                        </ChooseUsImage>
                    </Col>
                </Row>
            </Container>
        </WhyChooseUsSection>
    );
};
WhyChooseUsArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            description: PropTypes.string,
            description2: PropTypes.string,
            icon: PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]).isRequired,
                alt: PropTypes.string,
            }),
        }),
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                fields: PropTypes.shape({
                    slug: PropTypes.string,
                }),
                alt: PropTypes.string,
            })
        ),
    }),
};
export default WhyChooseUsArea;
